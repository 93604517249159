import React from 'react';
import SEO from "../components/SEO";
import Layout from "../components/Layout";
import {Link} from "gatsby";
import {graphql} from "gatsby";
import MyPortableText from "../components/MyPortableText";
import {GatsbyImage} from "gatsby-plugin-image";
import {Parallax} from "react-scroll-parallax";

export const query = graphql`
    {
        allSanityCoaching {
    edges {
      node {
        kooperation
        zusatzinfo
        eventinfo
        _rawIntrotext
        personas1 {
          text {
            _rawChildren
          }
          image {
            asset {
              gatsbyImageData
            }
          }
        }
        _rawPersonas1
        personas2 {
          text {
            _rawChildren
          }
          image {
            asset {
              gatsbyImageData
            }
          }
        }
        _rawPersonas2
        personas3 {
          text {
            _rawChildren
          }
          image {
            asset {
              gatsbyImageData
            }
          }
        }
        _rawPersonas3
        personas4 {
          text {
            _rawChildren
          }
          image {
            asset {
              gatsbyImageData
            }
          }
        }
        _rawPersonas4
        personas5 {
          text {
            _rawChildren
          }
          image {
            asset {
              gatsbyImageData
            }
          }
        }
        _rawPersonas5
      }
    }
  }
        }
    `;

const Coaching = ({data}) => {

    const coaching = data.allSanityCoaching.edges[0].node;

    return (
        <Layout newsletter={false} page={"coaching"} backBtn={true}>
            <SEO title={"Coaching"}/>
            <div className="coaching__outer">
                <div className="coaching__inner">
                    <div className="coaching__landing__outer">
                        <div className="coaching__landing__inner">
                            <div className="left coaching__title">
                                <h2>Coaching</h2>
                                <p className="demibold">{coaching.eventinfo}</p>
                                <div className="programm__zusatztext">
                                    <p className="small">{coaching.kooperation}</p>
                                    <p className="small">{coaching.zusatzinfo}</p>
                                </div>
                            </div>
                            <div className="right coaching__introtext">
                                <h3 className={""}>
                                    <MyPortableText value={coaching._rawIntrotext}></MyPortableText>
                                </h3>
                            </div>
                        </div>
                    </div>
                    <div className="coaching__personas__outer">
                        <div className="coaching__personas__inner">
                            <div className="coaching__category">
                                {coaching.personas1.map((el, index) => {
                                    if( index === 0){
                                        return (
                                            <div className="coaching__category__row reverse">
                                                <h2 className="mobile">Finanzierung & Förderungen</h2>
                                                <div className="left image">
                                                    <div className="mobile">
                                                        <GatsbyImage image={el.image.asset.gatsbyImageData} alt={"Coaching Finanzierung & Förderung"}></GatsbyImage>
                                                    </div>
                                                    <Parallax speed={5} className={"desktopblock"}>
                                                        <GatsbyImage image={el.image.asset.gatsbyImageData} alt={"Coaching Finanzierung & Förderung"}></GatsbyImage>
                                                    </Parallax>
                                                </div>
                                                <div className="right text">
                                                    <div className="mobile">
                                                        <MyPortableText value={coaching._rawPersonas1[index].text}></MyPortableText>
                                                    </div>
                                                    <Parallax speed={-10} className={"desktopblock"}>
                                                    <h2>Finanzierung & Förderungen</h2>
                                                    <MyPortableText value={coaching._rawPersonas1[index].text}></MyPortableText>
                                                    </Parallax>
                                                </div>
                                            </div>
                                        )
                                    }
                                    else {
                                        return (
                                            <div className="coaching__category__row reverse">
                                                <div className="left image">
                                                    <div className="mobile">
                                                        <GatsbyImage image={el.image.asset.gatsbyImageData} alt={"Coaching Finanzierung & Förderung"}></GatsbyImage>

                                                    </div>
                                                    <Parallax speed={5} className={"desktopblock"}>
                                                    <GatsbyImage image={el.image.asset.gatsbyImageData} alt={"Coaching Finanzierung & Förderung"}></GatsbyImage>
                                                    </Parallax>
                                                </div>
                                                <div className="right text">
                                                    <div className="mobile">
                                                        <MyPortableText value={coaching._rawPersonas1[index].text}></MyPortableText>

                                                    </div>
                                                    <Parallax speed={-10} className={"desktopblock"}>
                                                    <MyPortableText value={coaching._rawPersonas1[index].text}></MyPortableText>
                                                    </Parallax>
                                                </div>
                                            </div>
                                        )
                                    }
                                })}
                            </div>
                            <div className="coaching__category">
                                {coaching.personas2.map((el, index) => {
                                    if( index === 0){
                                        return (
                                            <div className="coaching__category__row">

                                                <div className="left text">
                                                    <div className="mobile">
                                                        <MyPortableText value={coaching._rawPersonas2[index].text}></MyPortableText>
                                                    </div>
                                                    <Parallax speed={-10} className={"desktopblock"}>
                                                        <h2>Networking & Lobbying</h2>
                                                        <MyPortableText value={coaching._rawPersonas2[index].text}></MyPortableText>
                                                    </Parallax>
                                                </div>
                                                <div className="right image">
                                                    <div className="mobile">
                                                        <GatsbyImage image={el.image.asset.gatsbyImageData} alt={"Coaching Networking & Lobbying"}></GatsbyImage>

                                                    </div>
                                                    <Parallax speed={5} className={"desktopblock"}>
                                                        <GatsbyImage image={el.image.asset.gatsbyImageData} alt={"Coaching Networking & Lobbying"}></GatsbyImage>
                                                    </Parallax>
                                                </div>
                                                <h2 className="mobile">Networking & Lobbying</h2>
                                            </div>
                                        )
                                    }
                                    else {
                                        return (
                                            <div className="coaching__category__row">
                                                <div className="left text">
                                                    <div className="mobile">
                                                        <MyPortableText value={coaching._rawPersonas2[index].text}></MyPortableText>

                                                    </div>
                                                    <Parallax speed={-10} className={"desktopblock"}>
                                                        <MyPortableText value={coaching._rawPersonas2[index].text}></MyPortableText>
                                                    </Parallax>
                                                </div>
                                                <div className="right image">
                                                    <div className="mobile">
                                                        <GatsbyImage image={el.image.asset.gatsbyImageData} alt={"Coaching Networking & Lobbying"}></GatsbyImage>

                                                    </div>
                                                    <Parallax speed={5} className={"desktopblock"}>
                                                        <GatsbyImage image={el.image.asset.gatsbyImageData} alt={"Coaching Networking & Lobbying"}></GatsbyImage>
                                                    </Parallax>
                                                </div>
                                            </div>
                                        )
                                    }
                                })}
                            </div>
                            <div className="coaching__category">
                                {coaching.personas3.map((el, index) => {
                                    if( index === 0){
                                        return (
                                            <div className="coaching__category__row reverse">
                                                <h2 className="mobile">Geschäftsmodelle</h2>

                                                <div className="left image">
                                                    <div className="mobile">
                                                        <GatsbyImage image={el.image.asset.gatsbyImageData} alt={"Coaching Geschäftsmodelle"}></GatsbyImage>

                                                    </div>
                                                    <Parallax speed={5} className={"desktopblock"}>
                                                        <GatsbyImage image={el.image.asset.gatsbyImageData} alt={"Coaching Geschäftsmodelle"}></GatsbyImage>
                                                    </Parallax>
                                                </div>
                                                <div className="right text">
                                                    <div className="mobile">
                                                        <MyPortableText value={coaching._rawPersonas3[index].text}></MyPortableText>
                                                    </div>
                                                    <Parallax speed={-10} className={"desktopblock"}>
                                                        <h2>Geschäftsmodelle</h2>
                                                        <MyPortableText value={coaching._rawPersonas3[index].text}></MyPortableText>
                                                    </Parallax>
                                                </div>
                                            </div>
                                        )
                                    }
                                    else {
                                        return (
                                            <div className="coaching__category__row reverse">
                                                <div className="left image">
                                                    <div className="mobile">
                                                        <GatsbyImage image={el.image.asset.gatsbyImageData} alt={"Coaching Geschäftsmodelle"}></GatsbyImage>

                                                    </div>
                                                    <Parallax speed={5} className={"desktopblock"}>
                                                        <GatsbyImage image={el.image.asset.gatsbyImageData} alt={"Coaching Geschäftsmodelle"}></GatsbyImage>
                                                    </Parallax>
                                                </div>
                                                <div className="right text">
                                                    <div className="mobile">
                                                        <MyPortableText value={coaching._rawPersonas3[index].text}></MyPortableText>

                                                    </div>
                                                    <Parallax speed={-10} className={"desktopblock"}>
                                                        <MyPortableText value={coaching._rawPersonas3[index].text}></MyPortableText>
                                                    </Parallax>
                                                </div>
                                            </div>
                                        )
                                    }
                                })}
                            </div>
                            <div className="coaching__category">
                                {coaching.personas4.map((el, index) => {
                                    if( index === 0){
                                        return (
                                            <div className="coaching__category__row">
                                                <div className="left text">
                                                    <div className="mobile">
                                                        <MyPortableText value={coaching._rawPersonas4[index].text}></MyPortableText>
                                                    </div>
                                                    <Parallax speed={-10} className={"desktopblock"}>
                                                        <h2>Networking & Lobbying</h2>
                                                        <MyPortableText value={coaching._rawPersonas4[index].text}></MyPortableText>
                                                    </Parallax>
                                                </div>
                                                <div className="right image">
                                                    <div className="mobile">
                                                        <GatsbyImage image={el.image.asset.gatsbyImageData} alt={"Coaching Social Media & Website"}></GatsbyImage>
                                                    </div>
                                                    <Parallax speed={5} className={"desktopblock"}>
                                                        <GatsbyImage image={el.image.asset.gatsbyImageData} alt={"Coaching Social Media & Website"}></GatsbyImage>
                                                    </Parallax>
                                                </div>
                                                <h2 className="mobile">Social Media & Website</h2>
                                            </div>
                                        )
                                    }
                                    else {
                                        return (
                                            <div className="coaching__category__row">
                                                <div className="left text">
                                                    <div className="mobile">
                                                        <MyPortableText value={coaching._rawPersonas4[index].text}></MyPortableText>
                                                    </div>
                                                    <Parallax speed={-10} className={"desktopblock"}>
                                                        <MyPortableText value={coaching._rawPersonas4[index].text}></MyPortableText>
                                                    </Parallax>
                                                </div>
                                                <div className="right image">
                                                    <div className="mobile">
                                                        <GatsbyImage image={el.image.asset.gatsbyImageData} alt={"Coaching Social Media & Website"}></GatsbyImage>
                                                    </div>
                                                    <Parallax speed={5} className={"desktopblock"}>
                                                        <GatsbyImage image={el.image.asset.gatsbyImageData} alt={"Coaching Social Media & Website"}></GatsbyImage>
                                                    </Parallax>
                                                </div>
                                            </div>
                                        )
                                    }
                                })}
                            </div>
                            <div className="coaching__category">
                                {coaching.personas5.map((el, index) => {
                                    return (
                                    <div className="coaching__category__row noimage">
                                        <div className="text">
                                            <div className={"mobile"}>
                                                <h2>PR & Kommunikation</h2>
                                                <MyPortableText value={coaching._rawPersonas5[index].text}></MyPortableText>
                                            </div>
                                            <Parallax speed={-10} className={"desktopblock"}>
                                                <h2>PR & Kommunikation</h2>
                                                <MyPortableText value={coaching._rawPersonas5[index].text}></MyPortableText>
                                            </Parallax>
                                        </div>
                                    </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                    <div className="section__outer">
                        <div className="section__inner">
                            <h4 className={"uppercase"}>MDZ</h4>
                            <div className="separator"></div>
                            <div className="coll__outer flexspacebetween">
                                <div className="coll3">
                                    <p className={"smallwidth col3"}>
                                        Mehr Infos zum <span className={"demibold"}>Markt der Zukunft</span> und dem Klimakulturfestival von 7.-9. Oktober findest du <Link to={"/"} className={"underline"}>hier</Link>.
                                    </p>
                                </div>
                                <div className="coll3 coaching desktop">
                                    <Link to={"/#programm"} className="btn__inner light icon--small">
                                        ZUM PROGRAMM
                                        <svg xmlns="http://www.w3.org/2000/svg" width="10.449" height="13.398" viewBox="0 0 10.449 13.398" style={{transform : "rotate(-90deg"}}>
                                            <g className={"svg-path-dark svg-path"} id="Group_47" data-name="Group 47" transform="translate(0.707)">
                                                <path id="Path_82" data-name="Path 82" d="M-852.781,666.193v10.725" transform="translate(857.298 -666.193)"/>
                                                <path id="Path_84" data-name="Path 84" d="M-857.731,675.45l4.517,4.517,4.517-4.517" transform="translate(857.731 -667.983)"/>
                                            </g>
                                        </svg>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
}

export default Coaching;
